import React, { useContext, useEffect } from "react";
import Main from "./Main";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { itIT } from "@mui/material/locale";
import { itIT as itITGrid } from "@mui/x-data-grid";
import { it } from "date-fns/locale";
import {
  AuthContextProvider,
  DashboardContextProvider,
  DocumentiContextProvider,
  IndirizziCodificatiContextProvider,
  NationsContext,
  PreventiviContextProvider,
  NationsContextProvider,
  OrdiniRitiroContextProvider,
  ThemeContext,
  TrackingContextProvider,
} from "./context";
import { getConfig, themeChoseLight } from "./utilities";
import { useState } from "react";
import "./App.css";
import { useTranslation } from 'react-i18next';
/* import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; */
/* import darkScrollbar from '@mui/material/darkScrollbar' */

function App() {
  const { i18n } = useTranslation();
  const {
    state: { theme, languages },
    darkMode,
    languagesChoose
  } = useContext(ThemeContext);

  /* const { getNationsListsTest,} = useContext(NationsContext); */


  const [configParmas, setConfigParmas] = useState(0);

  useEffect(() => {
    const fetchTheme = async () => {
      let conf = await getConfig(window.location.href);
      setConfigParmas(conf.themeWebTracking);
      localStorage.setItem("configurazioni", JSON.stringify(conf));
      /* getNationsListsTest() */
    };
    fetchTheme();
    if (localStorage.getItem("dark") === "false") {
      darkMode(Boolean(false));
    } else {
      darkMode(Boolean(true));
    }
    localStorage.setItem("path", window.location.href);
    languagesChoose(localStorage.getItem('languages'), i18n)
  }, []);

  let themeDark = createTheme();
  themeDark = createTheme(
    {
      palette: {
        mode: "dark",
        primary: {
          dark: "#ffffff",
          main: "#ffffff",
          light: "#ffffff",
        },
        secondary: {
          dark: "#dff7f1",
          main: "#02d6a1",
          light: "#86fcdf",
        },
        success: {
          dark: "#007717",
          main: "#28a745",
          light: "#64da73",
          contrastText: "#fff",
        },
        warning: {
          dark: "#b28704",
          main: "#E0B812",
          light: "#ffcd38",
        },
        error: {
          dark: "#b80000",
          main: "#f40400",
          light: "#ff5834",
          contrastText: "#fff",
        },
        text: {
          primary: "#fff",
        },
      },
    },
    itIT,
    itITGrid
  );

  let themeLight = createTheme();
  themeLight = createTheme(themeChoseLight[configParmas], itIT, itITGrid);

  return (
   
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
        <MuiThemeProvider theme={theme ? themeDark : themeLight}>
          <CssBaseline />
          <PreventiviContextProvider>
          <DocumentiContextProvider>
            <DashboardContextProvider>
              <OrdiniRitiroContextProvider>
                <IndirizziCodificatiContextProvider>
                  <TrackingContextProvider>
                    <NationsContextProvider>
                      <AuthContextProvider>
                        <Main></Main>
                      </AuthContextProvider>
                    </NationsContextProvider>
                  </TrackingContextProvider>
                </IndirizziCodificatiContextProvider>
              </OrdiniRitiroContextProvider>
            </DashboardContextProvider>
          </DocumentiContextProvider>
          </PreventiviContextProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
  );
}

export default App;
