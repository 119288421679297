import createDataContext from "./createContext";
import { WebTrakingService } from "../api";

const NATIONS_LISTS = "nations_lists"
const PROVINCES_LISTS_FROM = "provinces_lists_from"
const PROVINCES_LISTS_TO = "provinces_lists_to"

const INITIAL_STATE = {
  nations: [],
  provincesFrom:[],
  provincesTo:[],
  loading: true
};

const nationsReducer = (state, action) => {
  switch (action.type) {
    case NATIONS_LISTS: {
      return { ...state, nations:action.payload, loading:false };
    }
    case PROVINCES_LISTS_FROM: {
      return { ...state, provincesFrom:action.payload, loading:false };
    }
    case PROVINCES_LISTS_TO: {
      return { ...state, provincesTo:action.payload, loading:false };
    }
    default: {
      return state;
    }
  }
};

const getNationsListsData= (dispatch) => async (isoNation) => {
  let nazioni=localStorage.getItem("Nation")  
  if(JSON.parse(nazioni) === null){
    const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/nazioni?orderby=descrizione,codice`);
    localStorage.setItem("Nation", JSON.stringify(response.data.data)) 
    dispatch({ type: NATIONS_LISTS, payload:response.data.data });
  }else{
    dispatch({ type: NATIONS_LISTS, payload:JSON.parse(nazioni) });
  }
};

const getNationsLists = (dispatch) => async (isoNation, set) => {
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/nazioni?orderby=descrizione,codice`);
  getProvincesListsByNationFrom(dispatch)(isoNation, set)
  dispatch({ type: NATIONS_LISTS, payload:response.data.data });
};

const getProvincesListsByNationFrom = (dispatch) => async (isoNation, setOpenListLocalita) => {  
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/localita?orderby=nazione,localita,cap&nazione=${isoNation}`);
  if(response.data.Success){
    dispatch({ type: PROVINCES_LISTS_FROM , payload:response.data.data });
    setOpenListLocalita(true)
    localStorage.setItem("prov",JSON.stringify(response.data.data))
  }else{
    dispatch({ type: PROVINCES_LISTS_FROM , payload:[] });
    setOpenListLocalita(false)
  }
};

const getNationsListsTo = (dispatch) => async (isoNation) => {
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/nazioni?orderby=descrizione,codice`);
  getProvincesListsByNationTo(dispatch)(isoNation)
  localStorage.setItem("NationOther",JSON.stringify(response.data.data))
  dispatch({ type: NATIONS_LISTS, payload:response.data.data });
};


const getProvincesListsByNationTo = (dispatch) => async (isoNation) => {
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/localita?orderby=nazione,localita,cap&nazione=${isoNation}`);
  if(response.data.Success){
    dispatch({ type: PROVINCES_LISTS_TO , payload:response.data.data });
    localStorage.setItem("provOther",JSON.stringify(response.data.data))
  } else{
    dispatch({ type: PROVINCES_LISTS_TO , payload:[] });
  }
};


const svuotaCacheNations = (dispatch) => async () => {  
  dispatch({ type: PROVINCES_LISTS_FROM , payload:[] });
  dispatch({ type: PROVINCES_LISTS_TO , payload:[] });
};


export const { Provider, Context } = createDataContext(
  nationsReducer,
  {
    getNationsLists,
    getNationsListsTo,
    getProvincesListsByNationFrom,
    getProvincesListsByNationTo,
    svuotaCacheNations,
    getNationsListsData
  }, // actions
  INITIAL_STATE // initial state
);
