import * as React from "react";
import {
  ButtonGroup,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { PreventiviContext} from "../context";
import { useEffect } from "react";
import { AddBoxTwoTone } from "@mui/icons-material";
import { DialogFullScreen, FilterBar } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const Preventivi = () => {
  const { t } = useTranslation("common",{ keyPrefix: 'preventivi' })
  const [pageResetVar, setPageResetVar] = useState(false);
  const [ordineScelto, setOrdineScelto] = useState("");
  const [showButtonAdd, setShowButtonAdd] = useState(false);
 /*  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]); */
  const {
    state:{preventiviLists, columnsCustom, filter, recordCount, aggiornaFiltri, resetFiltri},
    getPreventivi,
  } = useContext(PreventiviContext);


  const navigate = useNavigate();

  useEffect(() => {
    titlePage("", t("titlePage"));
    getPreventivi("", limit, offset, navigate, [], recordCount,filter)
  }, []);

  const inserisciRitiro = (handleCloseDialog) => {
    /* postOrdine(ordineDaInviare, handleCloseDialog, navigate); */
  };

  const scegliTitoloMostraButton =(titolo, state)=>{
    setOrdineScelto(titolo)
    setShowButtonAdd(state)
  }

  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar
            type={false}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getPreventivi}
            resetFiltri={resetFiltri}
            setViewStatoWeb={true}
            setViewAdvFilter={true}
            setPageResetVar={setPageResetVar}
            listStatoWeb={[]}
          ></FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ButtonGroup size={"large"} mb={1}>
          <DialogFullScreen
            //child={<AddOrdiniRitiriNew dettaglioOrdineScelto={(titolo, state)=>scegliTitoloMostraButton(titolo, state)}/>}
            title={t("creaPreventivo")}
            subTitle={ordineScelto}
            showButton={showButtonAdd}
            startIcon={<AddBoxTwoTone />}
            actionMethod={(handleCloseDialog) =>
              inserisciRitiro(handleCloseDialog)
            }
          />
        </ButtonGroup>
        {columnsCustom.length > 0 ? (
          <CustomPaginationActionsTable
           /*  setCheckBoxSelectionPrint={setCheckBoxSelectionPrint} */
            rows={preventiviLists}
            columns={columnsCustom}
            limit={limit}
            navigate={navigate}
            getMethodForList={getPreventivi}
            recordCount={recordCount}
            idElement={"PrgOrdine"}
            routeDetail={"/preventivi"}
            actionShow={true}
            resetFiltri={resetFiltri}
            pageReset={pageResetVar}
            viewAction={{
              mail: false,
              view: false,
              location: false,
              print: false,
            }}
            filterList={filter}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>{t("loadPage")}</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
    </Grid>


  );
};

export default Preventivi;
