import * as React from "react";
import { Button, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DocumentiContext } from "../context";
import { useEffect } from "react";
import { FilterBar } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { PrintTwoTone } from "@mui/icons-material";

const limit = 100;
const offset = 0;

const Documenti = () => {
  const [pageResetVar, setPageResetVar] = useState(false);
  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]);
  const {
    state: { documentiList, columnsCustom, recordCount, filter },
    getDocumentiList,
    aggiornaFiltri,
    resetFiltri,
  } = useContext(DocumentiContext);

  const navigate = useNavigate();

  useEffect(() => {
    titlePage("", "Documenti");
    getDocumentiList(
      "",
      limit,
      offset,
      navigate,
      [],
      recordCount,
      false,
      filter
    );
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar
            setViewAdvFilter={false}
            type={true}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getDocumentiList}
            resetFiltri={resetFiltri}
            setViewStatoWeb={false}
            setPageResetVar={setPageResetVar}
            listStatoWeb={[]}
          ></FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {/* <Button
          variant="text"
          startIcon={<PrintTwoTone />}
          disabled={checkBoxSelectionPrint.length < 1}
          onClick={() =>
            console.log("i file da inviare sono", checkBoxSelectionPrint)
          }
        >
          Stampa{" "}
          {checkBoxSelectionPrint.length < 1
            ? ""
            : "n°" + checkBoxSelectionPrint.length}{" "}
          file selezionati
        </Button> */}
        {columnsCustom.length > 0 ? (
          <CustomPaginationActionsTable
            setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
            rows={documentiList}
            columns={columnsCustom}
            limit={limit}
            navigate={navigate}
            getMethodForList={getDocumentiList}
            recordCount={recordCount}
            idElement={"IDDocumento"}
            actionShow={true}
            routeDetail={"/documenti"}
            resetFiltri={resetFiltri}
            pageReset={pageResetVar}
            viewAction={{
              mail: false,
              view: false,
              location: false,
              download: true,
              print: false,
            }}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>Caricamento...</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Documenti;
