import { AutoFixHigh, DragIndicatorTwoTone, Loop, PersonPinOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import {
  IndirizziCodificatiContext,
  NationsContext,
  OrdiniRitiroContext,
} from "../../../context";
import { useForm } from "../../../hooks";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const Destinatario = ({
  indicatoriConfig,
  readOnly,
  luogoRitiroVisible,
  riferimentoVisible,
  setMainForm,
  mainForm
}) => {

  const {
    state: { indicatoriDest },
    getIndicatoriDest,
  } = useContext(OrdiniRitiroContext);

  const {
    state: { ricercaIndirizzi },
    getSearchIndirizziEsistenti,
  } = useContext(IndirizziCodificatiContext);

  const {
    state: { provincesFrom, nations, loading },
    getProvincesListsByNationFrom,
  } = useContext(NationsContext);
  
  let luogoRitiro = JSON.parse(localStorage.getItem("luogoRitiro"));

  const [checked, setChecked] = React.useState(false);
  const [openListRagSoc, setOpenListRagSoc] = React.useState(false);
  const [openListLocalita, setOpenListLocalita] = React.useState(false);
  const [animationRotate, setanimationRotate] = React.useState(false);
  const [checkedIndicatori, setCheckedIndicatori] = React.useState([]);
  const [localitaAll, setLocalitaAll] = React.useState(
    luogoRitiro.Cap +
      "⠀-⠀" +
      luogoRitiro.Localita +
      "⠀-⠀" +
      luogoRitiro.Provincia
  );

  const {
    values: destinatarioForm,
    changeHandler,
    touched,
    errors,
    setValues,
  } = useForm(
    {
      DestNazione: "IT",
      DestLocalita: "",
      DestProvincia: "",
      DestCAP: "",
      DestRagSoc: "",
      DestCodiceIndirizzoCodificato: "",
      DestIndirizzo: "",
      DestPersona: "",
      DestTelefono: "",
      DestEmail: "",
      DestRiferimento: "",
      NotaConsegna: "",
      IndicatoriDest: [],
    },
    []
  );

  useEffect(() => {
    getIndicatoriDest();
  }, []);

  useEffect(() => {
    setMainForm({...mainForm,
      DestNazione: destinatarioForm.DestNazione,
      DestLocalita: destinatarioForm.DestLocalita,
      DestProvincia: destinatarioForm.DestProvincia,
      DestCAP: destinatarioForm.DestCAP,
      DestRagSoc: destinatarioForm.DestRagSoc,
      DestCodiceIndirizzoCodificato: destinatarioForm.DestCodiceIndirizzoCodificato,
      DestIndirizzo: destinatarioForm.DestIndirizzo,
      DestPersona: destinatarioForm.DestPersona,
      DestTelefono: destinatarioForm.DestTelefono,
      DestEmail: destinatarioForm.DestEmail,
      DestRiferimento: destinatarioForm.DestRiferimento,
      NotaConsegna: destinatarioForm.NotaConsegna,
      Orari: destinatarioForm.Orari,
      IndicatoriDest: destinatarioForm.IndicatoriDest,
    });
  }, [destinatarioForm]);


  useEffect(() => {
    getProvincesListsByNationFrom(destinatarioForm.DestNazione, setOpenListLocalita);
  }, [destinatarioForm.DestNazione]);

  useEffect(() => {
    if (luogoRitiroVisible) {
      setValues({
        ...destinatarioForm,
        DestNazione: "IT",
        DestLocalita: luogoRitiro.Localita,
        DestProvincia: luogoRitiro.Provincia,
        DestCAP: luogoRitiro.Cap,
        DestRagSoc: luogoRitiro.RagSoc,
        DestIndirizzo: luogoRitiro.Indirizzo,
        DestPersona: luogoRitiro.Persona,
        DestTelefono: luogoRitiro.Telefono,
        DestEmail: luogoRitiro.Email,
        DestRiferimento: "",
        NotaConsegna: "",
        Orari: "",
      });
      setLocalitaAll(
        luogoRitiro.Cap +
          "⠀-⠀" +
          luogoRitiro.Localita +
          "⠀-⠀" +
          luogoRitiro.Provincia
      );
    } else {
      setValues({
        ...destinatarioForm,
        DestLocalita: destinatarioForm.DestLocalita,
        DestProvincia: destinatarioForm.DestProvincia,
        DestCAP: destinatarioForm.DestCAP,
      });
      if(destinatarioForm.DestCAP === "" && destinatarioForm.DestLocalita === "" && destinatarioForm.DestProvincia  === ""){
        setLocalitaAll("");
      }else{
        setLocalitaAll(
          destinatarioForm.DestCAP +
            "⠀-⠀" +
            destinatarioForm.DestLocalita +
            "⠀-⠀" +
            destinatarioForm.DestProvincia
        );
      }
    }
  }, [luogoRitiroVisible, nations, loading]);

  const changeValue = (newValue) => {
    setLocalitaAll(newValue);
    if (newValue) {
      setValues({
        ...destinatarioForm,
        DestLocalita: newValue.split("⠀-⠀")[1],
        DestProvincia: newValue.split("⠀-⠀")[2] ? newValue.split("⠀-⠀")[2] : "",
        DestCAP: newValue.split("⠀-⠀")[0],
      });
    } else {
      changeHandler("DestLocalita", newValue);
    }
  };

  const defaultDestinatario = () => {
    setChecked(!checked);
    if (!checked) {
      setanimationRotate(true);
      setValues({
        ...destinatarioForm,
        DestNazione: "IT",
        DestLocalita: "",
        DestProvincia: "",
        DestCAP: "",
        DestRagSoc: "",
        DestIndirizzo: "",
        DestPersona: "",
        DestTelefono: "",
        DestEmail: "",
        DestRiferimento: "",
        NotaConsegna: "",
        Orari: "",
      });
      setLocalitaAll("");
      setTimeout(() => {
        setanimationRotate(false);
      }, 1500);
    } else {
      setanimationRotate(true);
      setTimeout(() => {
        setanimationRotate(false);
      }, 1500);
      setValues({
        ...destinatarioForm,
        DestNazione: "IT",
        DestLocalita: luogoRitiro.Localita,
        DestProvincia: luogoRitiro.Provincia,
        DestCAP: luogoRitiro.Cap,
        DestRagSoc: luogoRitiro.RagSoc,
        DestIndirizzo: luogoRitiro.Indirizzo,
        DestPersona: luogoRitiro.Persona,
        DestTelefono: luogoRitiro.Telefono,
        DestEmail: luogoRitiro.Email,
        DestRiferimento: "",
        NotaConsegna: "",
        Orari: "",
      });
      setLocalitaAll(
        luogoRitiro.Cap +
          "⠀-⠀" +
          luogoRitiro.Localita +
          "⠀-⠀" +
          luogoRitiro.Provincia
      );
    }
  };


  const TrovaDestinatario = (codice, localita) => {
    let filtro = ricercaIndirizzi.filter(
      (res) => res.Codice === codice && res.Localita === localita
    );
    valoriTrovaDestinatario(filtro[0]);
  };

  function valoriTrovaDestinatario(filtro) {
    setValues({
      ...destinatarioForm,
      DestNazione: filtro.Nazione,
      DestLocalita: filtro.Localita,
      DestProvincia: filtro.Provincia,
      DestCAP: filtro.Cap,
      DestIndirizzo: filtro.Indirizzo,
      DestRagSoc: filtro.RagSoc,
      DestPersona: filtro.Persona,
      DestTelefono: filtro.Telefono,
      DestCodiceIndirizzoCodificato: filtro.Codice,
      DestEmail: filtro.Email,
      DestRiferimento: filtro.Riferimento,
    });
    /* setCurrentNation(filtro.Nazione) */
    setLocalitaAll(
      filtro.Cap + "⠀-⠀" + filtro.Localita + "⠀-⠀" + filtro.Provincia
    );
  }

  const changeIndicatoriChecked = (isChecked, code) => {
    if (isChecked) {
      setCheckedIndicatori([
        ...checkedIndicatori.filter((res) => res !== code),
        code,
      ]);
      setValues({
        ...destinatarioForm,
        IndicatoriDest: [
          ...checkedIndicatori.filter((res) => res !== code),
          code,
        ],
      });
    } else {
      setCheckedIndicatori([
        ...checkedIndicatori.filter((res) => res !== code),
      ]);
      setValues({
        ...destinatarioForm,
        IndicatoriDest: [...checkedIndicatori.filter((res) => res !== code)],
      });
    }
  };
  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.success.light + "25" }}
    >
      <CardHeader
        title={"Destinatario"}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        subheader={"Dati del destinatario"}
        avatar={<PersonPinOutlined />}
        action={
          <Stack direction={"row"} justifyContent="flex-end" spacing={2} p={2}>
            <Button
              color="warning"
              variant="contained"
              size="small"
              disabled={!checked}
              onClick={() => defaultDestinatario()}
              startIcon={<AutoFixHigh />}
            >
              destinatario di default
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={
                animationRotate ? (
                  <Loop
                    color="warning"
                    sx={{
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    }}
                  />
                ) : (
                  <Loop color="error" />
                )
              }
              disabled={checked}
              onClick={() => defaultDestinatario()}
            >
              Svuota Campi
            </Button>
          </Stack>
        }
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12} mt={2}>
            <Autocomplete
              id="destinatario-ragSoc"
              openOnFocus={openListRagSoc}
              freeSolo
              size="small"
              onOpen={() => setOpenListRagSoc(true)}
              onClose={() => setOpenListRagSoc(false)}
              forcePopupIcon={true}
              options={ricercaIndirizzi.map((option) => {
                return (
                  option.Codice +
                  "⠀-⠀" +
                  option.RagSoc +
                  "⠀-⠀" +
                  option.Localita
                );
              })}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={uuidv4()}>
                  {option}
                </Box>
              )}
              value={destinatarioForm.DestRagSoc || ""}
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  TrovaDestinatario(
                    newValue.split("⠀-⠀")[0],
                    newValue.split("⠀-⠀")[2]
                  );
                }
              }}
              inputValue={destinatarioForm.DestRagSoc || ""}
              onInputChange={(e, newValue) => {
                getSearchIndirizziEsistenti(newValue);
                changeHandler("DestRagSoc", newValue);
              }}
              getOptionLabel={(option) => option}
              loading={ricercaIndirizzi.length === 0 && openListRagSoc}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ragione Sociale"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <React.Fragment>
                        {ricercaIndirizzi.length === 0 && openListRagSoc ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.startAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Indirizzo"
              autoComplete="off"
              value={destinatarioForm.DestIndirizzo || ""}
              helperText={touched.DestIndirizzo && errors.DestIndirizzo}
              error={Boolean(touched.DestIndirizzo && errors.DestIndirizzo)}
              onChange={(e) => changeHandler("DestIndirizzo", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="select-from-nations">Nazione</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="select-from-nations"
                id="select-from-nations"
                value={destinatarioForm.DestNazione || ""}
                label="Nazione"
                onChange={(e) => {
                  setValues({
                    ...destinatarioForm,
                    DestNazione: e.target.value,
                    DestLocalita: "",
                    DestProvincia: "",
                    DestCAP: "",
                    DestIndirizzo: "",
                    DestRagSoc: "",
                    DestPersona: "",
                    DestTelefono: "",
                    DestCodiceIndirizzoCodificato: "",
                    DestEmail: "",
                    DestRiferimento: "",
                  });
                  setLocalitaAll("");
                }}
              >
                <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                {nations?.map((params, key) => (
                  <MenuItem key={key} value={params.nazioneiso}>
                    <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                      <img
                        loading="lazy"
                        width="20"
                        src={
                          params.nazioneiso?.toLowerCase() !== ""
                            ? `https://flagcdn.com/w20/${params.nazioneiso?.toLowerCase()}.png`
                            : ""
                        }
                        srcSet={
                          params.nazioneiso?.toLowerCase() !== ""
                            ? `https://flagcdn.com/w40/${params.nazioneiso?.toLowerCase()}.png 2x`
                            : ""
                        }
                        alt=""
                      />
                      {params.descrizione}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {destinatarioForm.DestNazione === "IT" ? (
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                freeSolo
                fullWidth
                forcePopupIcon={true}
                value={localitaAll || ""}
                inputValue={localitaAll || ""}
                openOnFocus={openListLocalita}
                onOpen={() => setOpenListLocalita(true)}
                onClose={() => setOpenListLocalita(false)}
                size="small"
                filterOptions={filterOptions}
                id="select-from-provinces"
                groupBy={(option) =>
                  destinatarioForm.DestNazione === "IT"
                    ? option.split("⠀-⠀")[1][0]
                    : option[0]
                }
                options={provincesFrom?.map((item) => {
                  return `${item.cap}⠀-⠀${item.localita}${
                    item.provincia ? `⠀-⠀${item.provincia}` : ""
                  }`;
                })}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={uuidv4()}>
                    {option}
                  </Box>
                )}
                loading={provincesFrom.length === 0}
                onInputChange={(e, newValue) => {
                  changeValue(newValue);
                }}
                onChange={(e, newValue) => {
                  changeValue(newValue);
                }}
                disabled={loading || readOnly}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cap - Località - Provincia "
                    sx={{ marginTop: 2 }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            ""
                          )}
                          {params.InputProps.startAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  forcePopupIcon={true}
                  value={destinatarioForm.DestLocalita || ""}
                  inputValue={destinatarioForm.DestLocalita || ""}
                  size="small"
                  openOnFocus={openListLocalita}
                  onOpen={() => setOpenListLocalita(true)}
                  onClose={() => setOpenListLocalita(false)}
                  id="select-from-provinces"
                  options={provincesFrom?.map((item) => {
                    return item.localita;
                  })}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={uuidv4()}>
                      {option}
                    </Box>
                  )}
                  loading={provincesFrom.length === 0}
                  onInputChange={(e, newValue) => {
                    changeHandler("DestLocalita", newValue);
                  }}
                  onChange={(e, newValue) => {
                    changeHandler("DestLocalita", newValue);
                  }}
                  disabled={loading || readOnly}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Località"
                      sx={{ marginTop: 2 }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              ""
                            )}
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  forcePopupIcon={true}
                  value={destinatarioForm.DestCAP || ""}
                  inputValue={destinatarioForm.DestCAP || ""}
                  size="small"
                  id="select-from-provinces"
                  options={provincesFrom.map((item) => {
                    return item.cap;
                  })}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={uuidv4()}>
                      {option}
                    </Box>
                  )}
                  onChange={(e, newValue) => changeHandler("DestCAP", newValue)}
                  onInputChange={(e, newValue) => {
                    changeHandler("DestCAP", newValue);
                  }}
                  disabled={loading || readOnly}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cap"
                      sx={{ marginTop: 2 }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              ""
                            )}
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Persona"
              autoComplete="off"
              value={destinatarioForm.DestPersona || ""}
              helperText={touched.DestPersona && errors.DestPersona}
              error={Boolean(touched.DestPersona && errors.DestPersona)}
              onChange={(e) => changeHandler("DestPersona", e.target.value)}
              //disabled={checked}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Telefono"
              autoComplete="off"
              value={destinatarioForm.DestTelefono || ""}
              helperText={touched.DestTelefono && errors.DestTelefono}
              error={Boolean(touched.DestTelefono && errors.DestTelefono)}
              onChange={(e) => changeHandler("DestTelefono", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          <Grid item xs={12} md={riferimentoVisible ? 6:12} lg={riferimentoVisible ? 6:12}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="E-mail"
              autoComplete="off"
              value={destinatarioForm.DestEmail || ""}
              helperText={touched.DestEmail && errors.DestEmail}
              error={Boolean(touched.DestEmail && errors.DestEmail)}
              onChange={(e) => changeHandler("DestEmail", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          {riferimentoVisible ?  
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Riferimento"
              autoComplete="off"
              value={destinatarioForm.DestRiferimento || ""}
              helperText={touched.DestRiferimento && errors.DestRiferimento}
              error={Boolean(touched.DestRiferimento && errors.DestRiferimento)}
              onChange={(e) => changeHandler("DestRiferimento", e.target.value)}
              //disabled={checked}
            />
          </Grid> :<></>}
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              disabled={readOnly}
              margin="normal"
              fullWidth
              size="small"
              label="Nota consegna"
              autoComplete="off"
              multiline
              value={destinatarioForm.NotaConsegna || ""}
              helperText={touched.NotaConsegna && errors.NotaConsegna}
              error={Boolean(touched.NotaConsegna && errors.NotaConsegna)}
              onChange={(e) => changeHandler("NotaConsegna", e.target.value)}
              //disabled={checked}
            />
          </Grid>
          {indicatoriConfig.visible ? (
            <Grid item xs={12} md={12} lg={12}>
              <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
                <CardHeader
                  subheader={"Accessori destinatario"}
                  subheaderTypographyProps={{
                    fontWeight: "bold",
                  }}
                  avatar={<DragIndicatorTwoTone color={"primary"} />}
                />
              </Card>
            </Grid>
          ) : (
            <></>
          )}
          {indicatoriConfig.visible ? (
            indicatoriDest.map((data, index) => {
              return (
                <Grid item xs={12} md={2} lg={2} key={index}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={checkedIndicatori?.includes(data.codice)}
                          onChange={(e) =>
                            changeIndicatoriChecked(
                              e.target.checked,
                              data.codice
                            )
                          }
                        />
                      }
                      label={data.descrizione}
                    />
                  </FormGroup>
                </Grid>
              );
            })
          ) : (
            <></>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Destinatario;
