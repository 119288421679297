import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OrdiniRitiroContext } from "../../context";
import { useForm } from "../../hooks";
import OrdiniEditFormContainer from "./OrdiniEditFormContainer";

const OrdiniMainFormUpdate = () => {
  const {
    state: { configOrder, orderDetail },
    putOrdine,
    getEditRitiro,
    getConfigModeOrder,
    pulisciOrderDetail,
  } = useContext(OrdiniRitiroContext);

  const { idRitiro } = useParams();

  const navigate = useNavigate();
  const [title, setTitle] = React.useState("");

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (idRitiro) {
      getEditRitiro(idRitiro);
    }
  }, [idRitiro]);

  useEffect(() => {
    getConfigModeOrder();
    return () => {
      pulisciOrderDetail();
    };
  }, []);

  const { values: addOrdiniForm, setValues } = useForm(
    {
      UploadFile: [],
      DataPronta: moment().format("YYYY-MM-DD"),
      OraPronta: "",
      MittNazione: "IT",
      MittLocalita: "",
      MittProvincia: "",
      MittCAP: "",
      MittRagSoc: "",
      MittCodiceIndirizzoCodificato: "",
      MittIndirizzo: "",
      MittPersona: "",
      MittTelefono: "",
      MittEmail: "",
      MittRiferimento: "",
      NotaRitiro: "",
      Orari: "",
      IndicatoriMitt: [],

      DestNazione: "IT",
      DestLocalita: "",
      DestProvincia: "",
      DestCAP: "",
      DestRagSoc: "",
      DestCodiceIndirizzoCodificato: "",
      DestIndirizzo: "",
      DestPersona: "",
      DestTelefono: "",
      DestEmail: "",
      DestRiferimento: "",
      NotaConsegna: "",
      IndicatoriDest: [],

      ServizioPerWeb: " ",
      ordmerce: [],
      StimaTipo: " ",
      StimaPeso: "",
      StimaVolume: "",
      StimaQuantita: "",
      NotaDimensioniMerce: "",
      ordadr: [],
      TipoIncassoCOD: " ",
      ImportoCOD: "",
      ValutaCOD: "EUR",

      StimaValMerce: "",
      StimaValMerceValuta: "EUR",
      ResaPerWeb: "",
      Resa: "",
      sceltaContrassegno: false,
      sceltaADR: false,
      sceltaAssicurazione: false,
    },
    []
  );

  useEffect(() => {
    setValues(orderDetail?.data);
  }, [orderDetail]);

  useEffect(() => {
    if (addOrdiniForm?.Resa) {
      switch (addOrdiniForm?.Resa) {
        case "PF":
          setTitle("Porto franco");
          break;
        case "PA":
          setTitle("Porto assegnato");
          break;
        case "T":
          setTitle("Triangolazione");
          break;
        case "TR":
          setTitle("Triangolazione");
          break;
        default:
          setTitle("");
          break;
      }
    }
  }, [addOrdiniForm?.Resa]);

  return (
    <div>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <Stack direction={"row"} spacing={2}>
            <Button
              color="warning"
              size={"small"}
              variant={"contained"}
              onClick={handleClose}
            >
              annulla
            </Button>
            <Button
              color="success"
              size={"small"}
              variant={"contained"}
              onClick={() => putOrdine(addOrdiniForm, handleClose, navigate)}
            >
              salva
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box pt={9} pb={1}>
        {addOrdiniForm?.Resa === "PF" ? (
          <>
            <OrdiniEditFormContainer
              configOrder={configOrder?.ritiri_franco}
              setMainForm={setValues}
              mainForm={addOrdiniForm}
              scrollOnBott={false}
            />
            <Stack direction={"row-reverse"} spacing={2} mt={2}>
              <Button
                color="success"
                size={"medium"}
                variant={"contained"}
                onClick={() => putOrdine(addOrdiniForm, handleClose, navigate)}
              >
                salva
              </Button>
              <Button
                color="warning"
                size={"medium"}
                variant={"contained"}
                onClick={handleClose}
              >
                annulla
              </Button>
            </Stack>
          </>
        ) : (
          <></>
        )}
        {addOrdiniForm?.Resa === "PA" ? (
          <>
            <OrdiniEditFormContainer
              configOrder={configOrder?.ritiri_assegnato}
              setMainForm={setValues}
              mainForm={addOrdiniForm}
              scrollOnBott={false}
            />
            <Stack direction={"row-reverse"} spacing={2} mt={2}>
              <Button
                color="success"
                size={"medium"}
                variant={"contained"}
                onClick={() => putOrdine(addOrdiniForm, handleClose, navigate)}
              >
                salva
              </Button>
              <Button
                color="warning"
                size={"medium"}
                variant={"contained"}
                onClick={handleClose}
              >
                annulla
              </Button>
            </Stack>
          </>
        ) : (
          <></>
        )}
        {addOrdiniForm?.Resa === "TR" || addOrdiniForm?.Resa === "T" ? (
          <>
            <OrdiniEditFormContainer
              configOrder={configOrder?.ritiri_triangolazione}
              setMainForm={setValues}
              mainForm={addOrdiniForm}
              scrollOnBott={false}
            />
            <Stack direction={"row-reverse"} spacing={2} mt={2}>
              <Button
                color="success"
                size={"medium"}
                variant={"contained"}
                onClick={() => putOrdine(addOrdiniForm, handleClose, navigate)}
              >
                salva
              </Button>
              <Button
                color="warning"
                size={"medium"}
                variant={"contained"}
                onClick={handleClose}
              >
                annulla
              </Button>
            </Stack>
          </>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};

export default OrdiniMainFormUpdate;
