import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  createFilterOptions,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { AuthContext, IndirizziCodificatiContext, NationsContext } from "../context";
import { useForm } from "../hooks";
import * as R from "ramda";
import { AccountBalanceTwoTone } from "@mui/icons-material";
import { isRequired } from "../hooks/useForm";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const AddIndirizziCodificati = () => {
  const {
    state: { nations, provincesFrom, loading },
    getNationsLists, svuotaCacheNations,
  } = useContext(NationsContext);

  const { addValidCheck } = useContext(AuthContext);

  const { aggiornaForm } = useContext(IndirizziCodificatiContext);

  const validations = [
    ({ RagSoc }) =>
      isRequired(RagSoc) || { RagSoc: "*Ragione Sociale obbligatoria" },
    ({ Indirizzo }) =>
      isRequired(Indirizzo) || { Indirizzo: "*Indirizzo obbligatoria" },
    ({ Nazione }) =>
      isRequired(Nazione) || { Nazione: "*Nazione obbligatoria" },
    ({ Cap }) => isRequired(Cap) || { Cap: "*Cap obbligatoria" },
    ({ Localita }) =>
      isRequired(Localita) || { Localita: "*Localita obbligatoria" },
  ];

  const {
    values: addIndirizziCodificatiForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      RagSoc: "",
      Indirizzo: "",
      Localita: "",
      Provincia: "",
      Cap: "",
      Nazione: "IT",
      Codice: "",
      Persona: "",
      Telefono: "",
     /*  Ncellulare: "", */
      Email: "",
      DestNote: "",
    },
    validations
  );

  useEffect(() => {
    addValidCheck(isValid);
  }, [isValid]);

  useEffect(() => {
    return function cleanup() {
      svuotaCacheNations()
    };
  }, []);

  useEffect(() => {
    aggiornaForm(addIndirizziCodificatiForm)
  }, [addIndirizziCodificatiForm]);

  useEffect(() => {
    getNationsLists(addIndirizziCodificatiForm.Nazione);
    if (addIndirizziCodificatiForm.Nazione !== "IT") {
      addIndirizziCodificatiForm.Cap = "";
      addIndirizziCodificatiForm.Localita = "";
      addIndirizziCodificatiForm.Provincia = "";
    }
  }, [addIndirizziCodificatiForm.Nazione]);

  const changeValue = (newValue) => {
    if(newValue){
      changeHandler("Localita", newValue);
      addIndirizziCodificatiForm.Cap = newValue.split(" - ")[0];
      addIndirizziCodificatiForm.Provincia = newValue.split(" - ")[2]
        ? newValue.split(" - ")[2]
        : "";
    }else{
      changeHandler("Localita", newValue);
    }
  };

  return (
    <Grid container spacing={2} mt={8} mb={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Card elevation={0}>
          <CardHeader title={"Anagrafica"} avatar={<AccountBalanceTwoTone />} />
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Codice"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.Codice || ""}
                  helperText={touched.Codice && errors.Codice}
                  error={Boolean(touched.Codice && errors.Codice)}
                  onChange={(e) => changeHandler("Codice", e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Ragione sociale"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.RagSoc || ""}
                  helperText={touched.RagSoc && errors.RagSoc}
                  error={Boolean(touched.RagSoc && errors.RagSoc)}
                  onChange={(e) => changeHandler("RagSoc", e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Indirizzo"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.Indirizzo || ""}
                  helperText={touched.Indirizzo && errors.Indirizzo}
                  error={Boolean(touched.Indirizzo && errors.Indirizzo)}
                  onChange={(e) => changeHandler("Indirizzo", e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Nazione</InputLabel>
                  <Select
                    fullWidth
                    disabled={loading}
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={addIndirizziCodificatiForm.Nazione || ""}
                    label="Nazione"
                    onChange={(e) => changeHandler("Nazione", e.target.value)}
                  >
                    <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                    {nations?.map((params, key) => (
                      <MenuItem key={key} value={params.nazioneiso}>
                        <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                          <img
                            loading="lazy"
                            width="20"
                            src={
                              params.nazioneiso?.toLowerCase() !== ""
                                ? `https://flagcdn.com/w20/${params.nazioneiso?.toLowerCase()}.png`
                                : ""
                            }
                            srcSet={
                              params.nazioneiso?.toLowerCase() !== ""
                                ? `https://flagcdn.com/w40/${params.nazioneiso?.toLowerCase()}.png 2x`
                                : ""
                            }
                            alt=""
                          />
                          {params.descrizione}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {addIndirizziCodificatiForm.Nazione === -1 ||
              addIndirizziCodificatiForm.Nazione === "IT" ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Autocomplete
                    fullWidth
                    value={addIndirizziCodificatiForm.Localita || ""}
                    inputValue={addIndirizziCodificatiForm.Localita || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.provincia === value.provincia || option.localita === value.localita}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    //groupBy={(option) => option.split(" - ")[1][0]}
                    options={provincesFrom?.map((item) => {
                      return `${item.cap} - ${item.localita}${
                        item.provincia ? ` - ${item.provincia}` : ""
                      }`;
                    })}
                    loading={loading}
                    onInputChange={(e, newValue) => {
                      changeValue(newValue);
                    }}
                    onChange={(e, newValue) => {
                      changeValue(newValue);
                    }}
                    disabled={loading}
                    //getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cap - Località - Provincia "
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    value={addIndirizziCodificatiForm.Localita || ""}
                    inputValue={addIndirizziCodificatiForm.Localita || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.provincia === value.provincia || option.localita === value.localita}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    //groupBy={(option) => option.split(" - ")[1][1]}
                    options={provincesFrom?.map((item) => {
                      return item.localita
                    })}
                    loading={loading}
                    onInputChange={(e, newValue) => {
                      changeHandler("Localita", newValue);
                    }}
                    onChange={(e, newValue) => {
                      changeHandler("Localita", newValue);
                    }}
                    disabled={loading}
                    //getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Località"
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {addIndirizziCodificatiForm.Nazione === -1 ||
              addIndirizziCodificatiForm.Nazione === "IT" ? (
                <></>
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    value={addIndirizziCodificatiForm.Cap || ""}
                    inputValue={addIndirizziCodificatiForm.Cap || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option === value}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    options={R.uniq(
                      provincesFrom.map((item) => {
                        return item.cap;
                      })
                    )}
                    onInputChange={(e, newValue) => {
                      changeHandler("Cap", newValue);
                    }}
                    disabled={loading}
                    onChange={(e, newValue) => changeHandler("Cap", newValue)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cap"
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Persona"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.Persona || ""}
                  helperText={touched.Persona && errors.Persona}
                  error={Boolean(touched.Persona && errors.Persona)}
                  onChange={(e) => changeHandler("Persona", e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Telefono"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.Telefono || ""}
                  helperText={touched.Telefono && errors.Telefono}
                  error={Boolean(touched.Telefono && errors.Telefono)}
                  onChange={(e) => changeHandler("Telefono", e.target.value)}
                  size="small"
                />
              </Grid>
              {/* <Grid item xs={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Ncellulare"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.Ncellulare || ""}
                  helperText={touched.Ncellulare && errors.Ncellulare}
                  error={Boolean(touched.Ncellulare && errors.Ncellulare)}
                  onChange={(e) => changeHandler("Ncellulare", e.target.value)}
                  size="small"
                />
              </Grid> */}
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.Email || ""}
                  helperText={touched.Email && errors.Email}
                  error={Boolean(touched.Email && errors.Email)}
                  onChange={(e) => changeHandler("Email", e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Nota interna"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.NotaBreve || ""}
                  helperText={touched.NotaBreve && errors.NotaBreve}
                  error={Boolean(touched.NotaBreve && errors.NotaBreve)}
                  onChange={(e) => changeHandler("NotaBreve", e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Note di consegna"
                  autoComplete="off"
                  value={addIndirizziCodificatiForm.DestNote || ""}
                  helperText={touched.DestNote && errors.DestNote}
                  error={Boolean(touched.DestNote && errors.DestNote)}
                  onChange={(e) => changeHandler("DestNote", e.target.value)}
                  size="small"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddIndirizziCodificati;
