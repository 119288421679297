import { CancelPresentationOutlined, Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { OrdiniRitiroContext } from "../../context";
import { useForm } from "../../hooks";
import PortoAssegnato from "./PortoAssegnato";
import PortoFranco from "./PortoFranco";
import Triangrolazione from "./Triangrolazione";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrdiniMainForm = () => {
  const {
    state: { configOrder },
    postOrdine,
    getConfigModeOrder,
    //putOrdine,
  } = useContext(OrdiniRitiroContext);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  const [title, setTitle] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [scegliModalita, setScegliModalita] = React.useState({
    portoFranco: false,
    portoAssegnato: false,
    triangolazione: false,
  });

  const handleClose = () => {
    navigate(-1);
  };

  const goBack = () => {
    setOpen(!open);
    navigate("/ritiri");
  };

  useEffect(() => {
    getConfigModeOrder();
  }, []);

  const {
    values: addOrdiniForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      UploadFile: [],
      DataPronta: moment().format("YYYY-MM-DD"),
      OraPronta: "",
      MittNazione: "IT",
      MittLocalita: "",
      MittProvincia: "",
      MittCAP: "",
      MittRagSoc: "",
      MittCodiceIndirizzoCodificato: "",
      MittIndirizzo: "",
      MittPersona: "",
      MittTelefono: "",
      MittEmail: "",
      MittRiferimento: "",
      NotaRitiro: "",
      Orari: "",
      IndicatoriMitt: [],

      DestNazione: "IT",
      DestLocalita: "",
      DestProvincia: "",
      DestCAP: "",
      DestRagSoc: "",
      DestCodiceIndirizzoCodificato: "",
      DestIndirizzo: "",
      DestPersona: "",
      DestTelefono: "",
      DestEmail: "",
      DestRiferimento: "",
      NotaConsegna: "",
      IndicatoriDest: [],
      
      ServizioPerWeb: " ",
      ordmerce: [],
      StimaTipo: " ",
      StimaPeso: "",
      StimaVolume: "",
      StimaQuantita: "",
      NotaDimensioniMerce: "",
      ordadr: [],
      TipoIncassoCOD: " ",
      ImportoCOD: "",
      ValutaCOD: "EUR",
      
      StimaValMerce: "",
      StimaValMerceValuta: "EUR",
      ResaPerWeb: "",
      Resa: "",
      sceltaContrassegno: false,
      sceltaADR: false,
      sceltaAssicurazione: false,
    },
    []
    );
    
  const scrollOnBott = (liveData) => {
    if (liveData) {
      scrollToBottom();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Card elevation={0}>
            <CardHeader
              title={"Modalità di ritiro"}
              action={
                <IconButton onClick={() => goBack()}>
                  <CancelPresentationOutlined color="error" />
                </IconButton>
              }
            />
            <Stack direction={"column"} justifyContent={"center"} spacing={3}>
              <Card
                elevation={1}
                sx={{
                  border: 2,
                  borderColor: (theme) => theme.palette.secondary.main,
                }}
              >
                <CardHeader
                  title={configOrder?.ritiri_franco?.titolo}
                  subheader={configOrder?.ritiri_franco?.sottotitolo}
                />
                <CardActions>
                  <Button
                    size={"small"}
                    variant="contained"
                    onClick={() => {
                      setOpen(!open);
                      setScegliModalita({
                        ...scegliModalita,
                        portoFranco: !scegliModalita.portoFranco,
                      });
                      setTitle("Porto franco");
                      setValues({
                        ...addOrdiniForm,
                        ResaPerWeb: "PF",
                        Resa: "PF",
                      });
                    }}
                  >
                    Seleziona
                  </Button>
                </CardActions>
              </Card>
              <Card
                elevation={1}
                sx={{
                  border: 2,
                  borderColor: (theme) => theme.palette.secondary.main,
                }}
              >
                <CardHeader
                  title={configOrder?.ritiri_assegnato?.titolo}
                  subheader={configOrder?.ritiri_assegnato?.sottotitolo}
                />
                <CardActions>
                  <Button
                    size={"small"}
                    variant="contained"
                    onClick={() => {
                      setOpen(!open);
                      setScegliModalita({
                        ...scegliModalita,
                        portoAssegnato: !scegliModalita.portoAssegnato,
                      });
                      setTitle("Porto assegnato");
                      setValues({
                        ...addOrdiniForm,
                        ResaPerWeb: "PA",
                        Resa: "PA",
                      });
                    }}
                  >
                    Seleziona
                  </Button>
                </CardActions>
              </Card>
              <Card
                elevation={1}
                sx={{
                  border: 2,
                  borderColor: (theme) => theme.palette.secondary.main,
                }}
              >
                <CardHeader
                  title={configOrder?.ritiri_triangolazione?.titolo}
                  subheader={configOrder?.ritiri_triangolazione?.sottotitolo}
                />
                <CardActions>
                  <Button
                    variant="contained"
                    size={"small"}
                    onClick={() => {
                      setOpen(!open);
                      setScegliModalita({
                        ...scegliModalita,
                        triangolazione: !scegliModalita.triangolazione,
                      });
                      setTitle("Porto triangolazione");
                      setValues({
                        ...addOrdiniForm,
                        ResaPerWeb: "TR",
                        Resa: "TR",
                      });
                    }}
                  >
                    Seleziona
                  </Button>
                </CardActions>
              </Card>
            </Stack>
          </Card>
        </DialogContent>
      </Dialog>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <Stack direction={"row"} spacing={2}>
            <Button
              color="warning"
              size={"small"}
              variant={"contained"}
              onClick={handleClose}
            >
              annulla
            </Button>
            <Button
              color="success"
              size={"small"}
              variant={"contained"}
              onClick={() => postOrdine(addOrdiniForm, handleClose, navigate)}
            >
              salva
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box pt={9} pb={1}>
        {scegliModalita.portoFranco ? (
          <PortoFranco
            configOrder={configOrder}
            setMainForm={setValues}
            mainForm={addOrdiniForm}
            scrollOnBott={scrollOnBott}
          />
        ) : (
          <></>
        )}
        {scegliModalita.portoAssegnato ? (
          <PortoAssegnato
            configOrder={configOrder}
            setMainForm={setValues}
            mainForm={addOrdiniForm}
            scrollOnBott={scrollOnBott}
          />
        ) : (
          <></>
        )}
        {scegliModalita.triangolazione ? (
          <Triangrolazione
            configOrder={configOrder}
            setMainForm={setValues}
            mainForm={addOrdiniForm}
            scrollOnBott={scrollOnBott}
          />
        ) : (
          <></>
        )}
        {!scegliModalita.portoAssegnato &&
        !scegliModalita.triangolazione &&
        !scegliModalita.portoFranco ? (
          <></>
        ) : (
          <Stack direction={"row-reverse"} spacing={2} mt={2}>
            <Button
              color="success"
              size={"medium"}
              variant={"contained"}
              onClick={() => postOrdine(addOrdiniForm, handleClose, navigate)}
            >
              salva
            </Button>
            <Button
              color="warning"
              size={"medium"}
              variant={"contained"}
              onClick={handleClose}
            >
              annulla
            </Button>
          </Stack>
        )}
      </Box>
    </div>
  );
};

export default OrdiniMainForm;
