import * as React from "react";
import {
  Avatar,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { NationsContext, TrackingContext } from "../context";
import { useEffect } from "react";
import {
  AddBoxTwoTone,
  LocalShipping,
  PrintTwoTone,
} from "@mui/icons-material";
import { DialogAlertDelete, FilterBar } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import AlertDialogSlide from "../components/AlertDialogSlide";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const listaStato =[
  { value:"INS", text:"Inserito"},
  { value:"INSDIS", text:"Distinta creata"},
  { value:"PRC", text:"Preso in carico"},
]

const Spedizioni = () => {
  const { t } = useTranslation("common", { keyPrefix: "spedizioni" });
  const [pageResetVar, setPageResetVar] = useState(false);
  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]);

  const {
    state: {
      spedizioniList,
      columnsCustomSped,
      recordCount,
      filter,
      configPrint,
      resettaFiltri,
      isReminder,
      azioni
    },
    aggiornaFiltri,
    resetFiltri,
    getConfigModeSpedizioni,
    getSpedizioniList,
    getPrintConfig,
    postCreaDistinta,
    delSped,
    spedizioniEmail,
  } = useContext(TrackingContext);

  const {
    getNationsListsData,
  } = useContext(NationsContext);
  
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (codicedoc, checkBoxSelectionPrint) => {
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");
    let lista = [];
    checkBoxSelectionPrint.forEach((element) => {
      lista = [...lista, element.PrgInterno];
    });
    window.open(
      `${splitPrm[0]}${"/spedizioni"}/print/${lista.toString()}/${codicedoc}`,
      "_blank",
      "noopener,noreferrer"
    );
    setOpen(false);
  };

  useEffect(() => {
    titlePage("", t("titlePage"));
    if(!isReminder){
      getSpedizioniList(
        "",
        limit,
        offset,
        navigate,
        [],
        recordCount,
        false,
        filter,
        false,
      );
    }
    getConfigModeSpedizioni();
    getPrintConfig();
    setReload(false);
    getNationsListsData()
  }, []);

  const deleteAction=(e) =>{
    setOpenAlertDelete(true)
    setIdDelete(e)
  }

  const emailActionSpedizioni=(e) =>{
    spedizioniEmail(e)
  }


  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <AlertDialogSlide isRemider={isReminder} />
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar
            /*   setOpenTabella={setOpenTabella} */
            type={true}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getSpedizioniList}
            resetFiltri={resetFiltri}
            setViewStatoWeb={true}
            setViewAdvFilter={true}
            setPageResetVar={setPageResetVar}
            listStatoWeb={listaStato}
          ></FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ButtonGroup size={"large"} orientation={"horizontal"}>
          <Button onClick={()=> navigate("/spedizioni/new")} startIcon={<AddBoxTwoTone />} variant="contained">{t("creaSpedizione")}</Button>
          <Button
            variant="text"
            startIcon={<PrintTwoTone />}
            disabled={checkBoxSelectionPrint.length < 1}
            onClick={() => setOpen(!open)}
          >
            {t("Stampa")}{" "}
            {checkBoxSelectionPrint.length < 1
              ? ""
              : "(" + checkBoxSelectionPrint.length + ")"}{" "}
          </Button>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t("SelezionaStampa")}</DialogTitle>
            <List sx={{ pt: 0 }}>
              {configPrint.map((print) => (
                <ListItemButton
                  onClick={() =>
                    handleListItemClick(print.codicedoc, checkBoxSelectionPrint)
                  }
                  key={print.codicedoc}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: red[400] }} size="small">
                      <PrintTwoTone color="inherit" size="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={print.descrizione} />
                </ListItemButton>
              ))}
            </List>
          </Dialog>
          <Button
            variant="text"
            startIcon={<LocalShipping />}
            disabled={checkBoxSelectionPrint.length < 1}
            onClick={() =>
              postCreaDistinta(
                checkBoxSelectionPrint,
                navigate,
                setCheckBoxSelectionPrint,
                setReload
              )
            }
          >
            {t("CreaDistinta")}
          </Button>
        </ButtonGroup>
        {columnsCustomSped.length > 0 ? (
          <CustomPaginationActionsTable
            setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
            rows={spedizioniList}
            columns={columnsCustomSped}
            limit={100}
            navigate={navigate}
            getMethodForList={getSpedizioniList}
            recordCount={recordCount}
            routeDetail={"/spedizioni"}
            idElement={"PrgInterno"}
            actionShow={true}
            viewAction={{
              mail: azioni?.email?.visible,
              view: true,
              location: false,
              print: false,
              edit:azioni?.modifica?.visible,
              delete:azioni?.annulla?.visible,
            }}
            pageSearchReset={false}
            pageReset={pageResetVar}
            filterList={filter}
            resettaFiltri={resettaFiltri}
            configPrint={configPrint}
            reload={reload}
            emailAction={(e)=>emailActionSpedizioni(e)}
            deleteAction={(e)=>deleteAction(e)}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>Caricamento...</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
      <DialogAlertDelete open={openAlertDelete} setOpen={setOpenAlertDelete} idDelete={idDelete} delFunc={delSped} setIdDelete={setIdDelete} title={"spedizione"}/>
    </Grid>
  );
};

export default Spedizioni;
