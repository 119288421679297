import createDataContext from "./createContext";
import { WebTrakingService } from "../api";

const INDIRIZZI_CODIFICATI_LISTS = "indirizzi_codificati_lists"
const INDIRIZZO_CODIFICATO = "indirizzo_codificato"
const POP_VAR_POST = "pop_var_post"
const COLUMNS_CUSTOM = "columns_custom";
const RICERCA_INDIRIZZI = "ricerca_indirizzi";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";

const INITIAL_STATE = {
  indirizziCodificatiLists: [],
  columnsCustom: [],
  stepper: [],
  indirizziDetail: [],
  postIndirizziVar:[],
  recordCount: 0,
  isValidAdd:null,
  ricercaIndirizzi:[],
  filter: {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
    spedizioneDaInviare: [],
  },
  resettaFiltri:false
};


const indirizziCodificatiReducer = (state, action) => {
  switch (action.type) {
    case INDIRIZZI_CODIFICATI_LISTS: {
      const { data, countRecord } = action.payload;
      return { ...state, indirizziCodificatiLists:data, recordCount:countRecord, resettaFiltri:false};
    }
    case INDIRIZZO_CODIFICATO: {
      return { ...state, indirizziDetail: action.payload };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case POP_VAR_POST: {
      return { ...state, postIndirizziVar: action.payload };
    }
    case RICERCA_INDIRIZZI: {
      return { ...state, ricercaIndirizzi: action.payload };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter, resettaFiltri:true };
    }
    default: {
      return state;
    }
  }
};

const getIndrizziCodificati = (dispatch) => async (rif, limit, offset, navigate, dataSet, recordCount, data, filterAnagrafiche) => {
  /* {valueSearch?.ragSoc === true && valueSearch?.codice === true  ? "&Codice+RagSoc": valueSearch?.ragSoc === true && valueSearch?.codice === false ? "&RagSoc" : valueSearch?.ragSoc === false && valueSearch?.codice === true ? "&Codice" : "&all"} */
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/websearch?limit=${limit}&offset=${offset}&all=${filterAnagrafiche.searchall}`);
  if (response.data) {
    let data = dataSet;
    if (dataSet.length === 0 || rif !== "") {
      dispatch({
        type: INDIRIZZI_CODIFICATI_LISTS,
        payload: {
          data: response.data.data,
          countRecord: response.data.recordcount_total,
        },
      });
    } else if (dataSet.length < recordCount) {
      response.data.data.forEach((element) => {
        data.push(element);
      });
      dispatch({
        type: INDIRIZZI_CODIFICATI_LISTS,
        payload: {
          data: data,
          countRecord: response.data.recordcount_total,
        },
      });
    }
  }
  let col = [
   /*  {
      field: "checkbox",
      headerName: "",
      //flex: 1,
    }, */
    {
      field: "action",
      headerName: "Azioni",
      //flex: 1,
    },
  ];
  response.data.columns.forEach((data) => {
    col = [
      ...col,
      {
        field: data.id,
        headerName: data.label,
        minWidth: data.minWidth,
        flex: 1,
      },
    ];
  });
  dispatch({ type: COLUMNS_CUSTOM, payload: col });
};


const getIndrizziCodificato = (dispatch) => async (idIndCod) => {
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/${idIndCod}`);
  if(response){
    dispatch({ type: INDIRIZZO_CODIFICATO, payload: response.data.data });
  }
}

const getSearchIndirizziEsistenti = (dispatch)=> async(testoRicerca, popola)=>{
    const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/websearch?Codice+RagSoc=${testoRicerca}`);
    if(response){
      dispatch({ type: RICERCA_INDIRIZZI, payload: response.data.data });
    }else{
      dispatch({ type: RICERCA_INDIRIZZI, payload: [] });
    }
}

const putIndrizziCodificato = (dispatch) => async (idIndCod, formAggiornato, navigate) => {
  if(formAggiornato.Localita.split("-")[1]){
    formAggiornato.Localita = formAggiornato.Localita.split("-")[1]
  }
  const response = await WebTrakingService.put(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/${idIndCod}`, JSON.stringify(formAggiornato));
  if(response){
    dispatch({ type: INDIRIZZO_CODIFICATO, payload: response.data.data });
    getIndrizziCodificati(dispatch)("", 100, 0, "", "", "", "all")
    navigate("/indirizzi-codificati")
  }
}

const postIndrizziCodificato = (dispatch) => async (formAggiornato, handleCloseDialog) => {
  if(formAggiornato.Localita.split("-")[1]){
    formAggiornato.Localita = formAggiornato.Localita.split("-")[1]
  }
  const response = await WebTrakingService.post(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati`, 
  JSON.stringify(formAggiornato));
  if(response){
    getIndrizziCodificati(dispatch)("", 100, 0, "", "", "",{
      searchall: "",
      DataIniziale: "",
      DataFinale: "",
      Riferimento: "",
      DestProvincia: "",
      DestLocalita: "",
      DestRagSoc: "",
      DestNazione: "",
      StatoWeb: "",
      spedizioneDaInviare: [],
    },)
    handleCloseDialog()

  }
}

const aggiornaForm = (dispatch) => async (fromInAggiornamento) => {
  dispatch({ type: POP_VAR_POST, payload: fromInAggiornamento });
}

const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getIndrizziCodificati(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
  })
};

export const { Provider, Context } = createDataContext(
indirizziCodificatiReducer,
  {
    getIndrizziCodificati,
    postIndrizziCodificato,
    getIndrizziCodificato,
    putIndrizziCodificato,
    aggiornaForm,
    getSearchIndirizziEsistenti,
    aggiornaFiltri,
    resetFiltri,
  }, // actions
  INITIAL_STATE // initial state
);
