import createDataContext from "./createContext";
import { WebTrakingService } from "../api";
import moment from "moment";
import { sendNotification } from "../hooks/useNotification";
import { DialogsCustom, NotificationContent } from "../components";
import { sendDialogCustuom } from "../hooks/useDialogCustuom";
import { Button } from "@mui/material";

const TRACKING_LIST = "tracking_list";
const COLUMNS_CUSTOM = "columns_custom";
const COLUMNS_DETAIL = "columns_detail";
const IMG_BASE64 = "img_base64";
const TRACKING_DETAIL = "tracking_detail";
const STEPPER = "stepper";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";
const SPEDIZIONE_DA_INVIARE = "spedizione_da_inviare";
const CONFIG_SPED = "config_sped";
const ERROR_MESSAGGE = "error_messagge";
const CONFIG_PRINT = "config_Print";
const SPEDIZIONI_LIST = "spedizioni_list";
const SPEDIONI_DETAIL = "spedizioni_detail";
const SPEDIONI_DETAIL_EDIT = "spedizioni_detail_edit";
const COLUMNS_CUSTOM_SPED = "columns_custom_sped";

const INITIAL_STATE = {
  trackingList: [],
  spedizioniList: [],
  columnsCustom: [],
  columnsCustomDetail: [],
  image: null,
  stepper: [],
  configSped: [],
  fileName: "",
  spedDetail: [
    {
      data: [],
      columns: [],
      displaytext: "",
    },
  ],
  trackingDetail: [
    {
      data: [],
      columns: [],
      displaytext: "",
    },
  ],
  isReminder: false,
  recordCount: 0,
  errorMessage2: "",
  filter: {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
    spedizioneDaInviare: [],
  },
  resettaFiltri: false,
  ricercaFiltri: false,
  configPrint: [],
  columnsCustomSped: [],
  azioni: [],
  spedDetailEdit: {},
};

const trackingReducer = (state, action) => {
  switch (action.type) {
    case TRACKING_LIST: {
      const { data, countRecord, azioniTrak } = action.payload;
      return {
        ...state,
        trackingList: data,
        recordCount: countRecord,
        resettaFiltri: false,
        azioni: azioniTrak,
      };
    }
    case STEPPER: {
      return { ...state, stepper: action.payload };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case COLUMNS_CUSTOM_SPED: {
      return { ...state, columnsCustomSped: action.payload };
    }
    case COLUMNS_DETAIL: {
      return { ...state, columnsCustomDetail: action.payload };
    }
    case IMG_BASE64: {
      const { base64, fileName } = action.payload;
      return { ...state, image: base64, fileName: fileName };
    }
    case TRACKING_DETAIL: {
      return { ...state, trackingDetail: action.payload };
    }
    case SPEDIONI_DETAIL: {
      return { ...state, spedDetail: action.payload };
    }
    case SPEDIONI_DETAIL_EDIT: {
      return { ...state, spedDetailEdit: action.payload };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter, resettaFiltri: true };
    }
    case SPEDIZIONE_DA_INVIARE: {
      return { ...state, spedizioneDaInviare: action.payload };
    }
    case SPEDIZIONI_LIST: {
      const { data, countRecord, remider, azioniSped } = action.payload;
      return {
        ...state,
        spedizioniList: data,
        recordCount: countRecord,
        resettaFiltri: false,
        isReminder: remider,
        azioni: azioniSped,
      };
    }
    case CONFIG_SPED: {
      return { ...state, configSped: action.payload };
    }
    case ERROR_MESSAGGE: {
      return { ...state, errorMessage2: action.payload };
    }
    case CONFIG_PRINT: {
      return { ...state, configPrint: action.payload };
    }
    default: {
      return state;
    }
  }
};

const getTrackingList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking
  ) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/websearch?limit=${limit}&offset=${offset}&riferimento=${
        filterTracking ? filterTracking.Riferimento : ""
      }&searchall=${
        filterTracking ? filterTracking.searchall : ""
      }&datainiziale=${
        filterTracking === undefined || filterTracking?.DataIniziale === ""
          ? ""
          : moment(filterTracking?.DataIniziale).format("YYYY-MM-DD")
      }&datafinale=${
        filterTracking === undefined || filterTracking?.DataFinale === ""
          ? ""
          : moment(filterTracking?.DataFinale).format("YYYY-MM-DD")
      }&destprovincia=${
        filterTracking ? filterTracking.DestProvincia : ""
      }&destlocalita=${
        filterTracking ? filterTracking.DestLocalita : ""
      }&destragsoc=${
        filterTracking ? filterTracking.DestRagSoc : ""
      }&destnazione=${
        filterTracking ? filterTracking.DestNazione : ""
      }&statoweb=${filterTracking ? filterTracking.StatoWeb : ""}`
    );
    if (response.data) {
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: TRACKING_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
            azioniTrak: response.data.azioni,
          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: TRACKING_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
            azioniTrak: response.data.azioni,
            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }
    let col = [
      /*  {
        field: "checkbox",
        headerName: "",
        //flex: 1,
      }, */
      {
        field: "action",
        headerName: "Azioni",
        //flex: 1,
      },
    ];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
  };

const downloadPOD = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/pod/${PrgInterno}`
  );
  if (response.data) {
    let image = response.data.imagebase64;
    dispatch({
      type: IMG_BASE64,
      payload: { fileName: response.data.filename, base64: image },
    });
  }
};

const getTrackingDetail = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/viewtracking/${PrgInterno}`
  );
  if (response.data) {
    let col = [];
    let filter = response.data.data.filter(
      (res) => res.Lat !== "" && res.Lon !== ""
    );
    if (filter.length > 0) {
      col = [
        /*  {
          field: "action",
          headerName: "Azioni",
          //flex: 1,
        }, */
      ];
    }
    response.data.columns.forEach((data) => {
      if (data.id !== "Lat" && data.id !== "Lon") {
        col = [
          ...col,
          {
            field: data.id,
            headerName: data.label,
            minWidth: data.minWidth,
          },
        ];
      }
    });
    dispatch({ type: STEPPER, payload: response.data.displaystatus });
    dispatch({ type: COLUMNS_DETAIL, payload: col });
    dispatch({ type: TRACKING_DETAIL, payload: response.data });
  }
};

const getInfoTrackingSearch =
  (dispatch) => async (codeSearch, navigate, server) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/public/spedizioni/websearchsingle/${codeSearch}`
    );
    if (response.data) {
      let col = [];
      response.data.columns.forEach((data) => {
        if (data.id !== "Lat" && data.id !== "Lon") {
          col = [
            ...col,
            {
              field: data.id,
              headerName: data.label,
              minWidth: 100,
              sortable: false,
              //flex:1
            },
          ];
        }
      });
      dispatch({ type: STEPPER, payload: response.data.displaystatus });
      dispatch({ type: COLUMNS_CUSTOM, payload: col });
      dispatch({ type: TRACKING_DETAIL, payload: response.data });
    }
  };

const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getTrackingList(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
  });
  getSpedizioniList(dispatch)(
    "",
    100,
    0,
    false,
    [],
    0,
    false,
    {
      searchall: "",
      DataIniziale: "",
      DataFinale: "",
      Riferimento: "",
      DestProvincia: "",
      DestLocalita: "",
      DestRagSoc: "",
      DestNazione: "",
      StatoWeb: "",
    },
    false
  );
};

const popolaArraySpedizione = (dispatch) => async (payload) => {
  dispatch({ type: SPEDIZIONE_DA_INVIARE, payload: payload });
};

const getConfigModeSpedizioni = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/config`
  );
  if (response) {
    dispatch({ type: CONFIG_SPED, payload: response.data.data.spedizioni });
  }
};

const getSpedizioniList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking,
    remider,
  ) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/websearch?limit=${limit}&offset=${offset}&riferimento=${
        filterTracking ? filterTracking.Riferimento : ""
      }&searchall=${
        filterTracking ? filterTracking.searchall : ""
      }&datainiziale=${
        filterTracking === undefined || filterTracking?.DataIniziale === ""
          ? ""
          : moment(filterTracking?.DataIniziale).format("YYYY-MM-DD")
      }&datafinale=${
        filterTracking === undefined || filterTracking?.DataFinale === ""
          ? ""
          : moment(filterTracking?.DataFinale).format("YYYY-MM-DD")
      }&destprovincia=${
        filterTracking ? filterTracking.DestProvincia : ""
      }&destlocalita=${
        filterTracking ? filterTracking.DestLocalita : ""
      }&destragsoc=${
        filterTracking ? filterTracking.DestRagSoc : ""
      }&destnazione=${
        filterTracking ? filterTracking.DestNazione : ""
      }&statoweb=${filterTracking ? filterTracking.StatoWeb : ""}`
    );
    if (response.data) {
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: SPEDIZIONI_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
            remider: remider,
            azioniSped: response.data.azioni,
          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: SPEDIZIONI_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
            remider: remider,
            azioniSped: response.data.azioni,
            /*  ? response.data.data.length
              : response.data.recordcount_total, */
          },
        });
      }
    }
    let col = [
      {
        field: "checkbox",
        headerName: "",
        //flex: 1,
      },
      {
        field: "action",
        headerName: "Azioni",
        //flex: 1,
      },
    ];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM_SPED, payload: col });
  };

const getPrintFile = (dispatch) => async (PrgInterno, idModStampa) => {
  if (idModStampa !== "null") {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/print?codicedoc=${idModStampa}&prgspedizioni=${PrgInterno}`
    );
    if (response.data) {
      let image = response.data.imagebase64;
      dispatch({
        type: IMG_BASE64,
        payload: { fileName: response.data.filename, base64: image },
      });
    }
  }
};

const getPrintConfig = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/print/config`
  );
  if (response.data) {
    dispatch({
      type: CONFIG_PRINT,
      payload: response.data.data.docs,
    });
  }
};

const postSped = (dispatch) => async (sped, handleCloseDialog, navigate) => {
  const response = await WebTrakingService.post(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/spedizioni`,
    sped
  );
  if (response) {
    /* mettere il controllo del messaggio */
    if (response.data.Success) {
      handleCloseDialog();
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        false,
      );
      sendDialogCustuom(
        <NotificationContent service={"Promemoria!"} />,
        "Ricordati di inserire il ritiro per la spedizione appena creata",
        "error",
        100,
        navigate,
        "/ritiri/new",
        "inserisci ritiro"
      );
      sendNotification(
        <NotificationContent service={"Spedizione creata!"} />,
        response.data.Message,
        "success",
        5
      );
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5,
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
};

const putSped = (dispatch) => async (sped, handleCloseDialog, navigate) => {
  const response = await WebTrakingService.put(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/${sped.PrgInterno}`,
    sped
  );
  if (response) {
    /* mettere il controllo del messaggio */
    if (response.data.Success) {
      handleCloseDialog();
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      sendNotification(
        <NotificationContent service={"Spedizione modificata!"} />,
        "Spedizione modificata con successo",
        "success",
        5
      );
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        true
      );
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
};

const delSped = (dispatch) => async (PrgInterno, navigate) => {
  const response = await WebTrakingService.delete(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/${PrgInterno}`
  );
  if (response) {
    if (response.data.Success) {
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      sendNotification(
        <NotificationContent service={"Spedizione eliminata!"} />,
        "Spedizione eliminata con successo",
        "success",
        5
      );
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        false
      );
    } else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
};

const errorCancel2 = (dispatch) => async () => {
  dispatch({ type: ERROR_MESSAGGE, payload: "" });
};

const getSpedizioneDetail = (dispatch) => async (PrgInterno) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/view/${PrgInterno}`
  );
  if (response.data) {
    let col = [];
    response.data.columns.forEach((data) => {
      if (data.id !== "Lat" && data.id !== "Lon") {
        col = [
          ...col,
          {
            field: data.id,
            headerName: data.label,
            minWidth: 100,
            sortable: false,
            //flex:1
          },
        ];
      }
    });
    dispatch({ type: STEPPER, payload: response.data.displaystatus });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
    dispatch({ type: SPEDIONI_DETAIL, payload: response.data });
  }
};

const getEditSpedizione = (dispatch) => async (prgSped) => {
  dispatch({ type: SPEDIONI_DETAIL_EDIT, payload: {} });
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/${prgSped}?action=edit`
  );
  if (response.data) {
    dispatch({ type: SPEDIONI_DETAIL_EDIT, payload: response.data.data });
  }
};

const postCreaDistinta =
  (dispatch) =>
  async (data, navigate, setCheckBoxSelectionPrint, setReload) => {
    setReload(true);
    setTimeout(() => setReload(false), 1000);
    setCheckBoxSelectionPrint([]);
    let prgSpedizioni = "";
    data.forEach((element) => {
      prgSpedizioni = prgSpedizioni + element.PrgInterno + ",";
    });
    const response = await WebTrakingService.post(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/spedizioni/spedizioniweb/creadistinta`,
      { prgspedizioni: prgSpedizioni }
    );
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Distinta creata!"} />,
        response.data.Message,
        "success",
        5
      );
      getSpedizioniList(dispatch)(
        "",
        100,
        0,
        false,
        [],
        0,
        false,
        {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          DestProvincia: "",
          DestLocalita: "",
          DestRagSoc: "",
          DestNazione: "",
          StatoWeb: "",
        },
        false
      );
    }
  };

const pulisciSpedDetail = (dispatch) => async () => {
  dispatch({ type: SPEDIONI_DETAIL, payload: {} });
  dispatch({ type: SPEDIONI_DETAIL_EDIT, payload: {} });
};

const spedizioniEmail = (dispatch) => async (prgSped) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/mailto/${prgSped}?type=info`
  );
  if (response.data) {
    window.location = response.data.mailto;
    //dispatch({ type: SPEDIONI_DETAIL, payload: response.data });
  }
};

const trackEmail = (dispatch) => async (prgTrak) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/spedizioniweb/mailto/${prgTrak}?type=tracking`
  );
  if (response.data) {
    window.location = response.data.mailto;
    //dispatch({ type: SPEDIONI_DETAIL, payload: response.data });
  }
};

export const { Provider, Context } = createDataContext(
  trackingReducer,
  {
    getTrackingList,
    downloadPOD,
    getTrackingDetail,
    getInfoTrackingSearch,
    aggiornaFiltri,
    resetFiltri,
    postSped,
    popolaArraySpedizione,
    getConfigModeSpedizioni,
    getSpedizioniList,
    getPrintFile,
    errorCancel2,
    getPrintConfig,
    getSpedizioneDetail,
    postCreaDistinta,
    getEditSpedizione,
    putSped,
    delSped,
    pulisciSpedDetail,
    spedizioniEmail,
    trackEmail,
  }, // actions
  INITIAL_STATE // initial state
);
