import * as React from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { IndirizziCodificatiContext } from "../context";
import { useEffect } from "react";
import { AddBoxTwoTone, PrintTwoTone } from "@mui/icons-material";
import {
  AddIndirizziCodificati,
  DialogFullScreen,
  FilterBar,
} from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const IndirizziCodificati = () => {
  const { t } = useTranslation("common",{ keyPrefix: 'anagrafiche' });
  const [pageResetVar, setPageResetVar] = useState(false)
  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]);

  const {
    state: {
      indirizziCodificatiLists,
      columnsCustom,
      recordCount,
      postIndirizziVar,
      filter,
    },
    getIndrizziCodificati,
    postIndrizziCodificato,
    aggiornaFiltri,
    resetFiltri,
  } = useContext(IndirizziCodificatiContext);

  const navigate = useNavigate();

  useEffect(() => {
    titlePage("", "Anagrafiche");
    getIndrizziCodificati("", limit, offset, navigate, [], recordCount,filter);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar 
          setViewAdvFilter={false}
          type={true}
          recordCount={recordCount}
          filter={filter}
          aggiornaFiltri={aggiornaFiltri}
          getMethod={getIndrizziCodificati}
          resetFiltri={resetFiltri}
          setViewStatoWeb={false}
          setPageResetVar={setPageResetVar}
          listStatoWeb={[]}
          >
          </FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ButtonGroup size={"large"} mb={2}>
          <DialogFullScreen
            child={<AddIndirizziCodificati />}
            title={t("creaAnagrafiche")}
            showButton={true}
            startIcon={<AddBoxTwoTone />}
            actionMethod={(handleCloseDialog) =>
              postIndrizziCodificato(postIndirizziVar, handleCloseDialog)
            }
          />
        </ButtonGroup>
        {columnsCustom.length > 0 ? (
          <CustomPaginationActionsTable
            setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
            rows={indirizziCodificatiLists}
            columns={columnsCustom}
            limit={100}
            navigate={navigate}
            getMethodForList={getIndrizziCodificati}
            recordCount={recordCount}
            idElement={"PrgIndirizzo"}
            actionShow={true}
            pageSearchReset={false}
            pageReset={pageResetVar}
            filterList={filter}
            routeDetail={"/indirizzi-codificati"}
            viewAction={{ mail: false, view: true, location: false, print:false }}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>Caricamento...</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default IndirizziCodificati;
