import { AllInbox } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useForm } from "../../../hooks";

const TotaleMerce = ({ mainForm, setMainForm }) => {
  const {
    values: totaleMerceForm,
    changeHandler,
  } = useForm(
    {
      StimaTipo: mainForm.StimaTipo,
      StimaPeso: mainForm.StimaPeso,
      StimaVolume: mainForm.StimaVolume,
      StimaQuantita: mainForm.StimaQuantita,
      NotaDimensioniMerce: mainForm.NotaDimensioniMerce,
    },
    []
  );

  useEffect(() => {
    setMainForm({
      ...mainForm,
      StimaTipo: totaleMerceForm.StimaTipo,
      StimaPeso: totaleMerceForm.StimaPeso,
      StimaVolume: totaleMerceForm.StimaVolume,
      StimaQuantita: totaleMerceForm.StimaQuantita,
      NotaDimensioniMerce: totaleMerceForm.NotaDimensioniMerce,
    });
  }, [totaleMerceForm]);

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Totale dati merce"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<AllInbox />}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={3} lg={3} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="select-from-nations">Tipo</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="select-from-nations"
                id="select-from-nations"
                value={totaleMerceForm.StimaTipo || ""}
                onChange={(e) => changeHandler("StimaTipo", e.target.value)}
                label="Tipo"
              >
                <MenuItem value={" "}>Seleziona tipo</MenuItem>
                <MenuItem value={"C"}>Colli</MenuItem>
                <MenuItem value={"P"}>Palette</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              label="Quantità"
              autoComplete="off"
              type="number"
              InputProps={{
                inputProps: { min: 0 }
              }}
              value={totaleMerceForm.StimaQuantita || ""}
              onChange={(e) => changeHandler("StimaQuantita", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              label="Peso (kg)"
              autoComplete="off"
              InputProps={{
                inputProps: { min: 0 }
              }}
              type="number"
              value={totaleMerceForm.StimaPeso || ""}
              onChange={(e) =>
                changeHandler("StimaPeso", e.target.value)
              }
              //disabled={checked}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              label="Volume (mc)"
              autoComplete="off"
              type="number"
              InputProps={{
                inputProps: { min: 0 }
              }}
              value={totaleMerceForm.StimaVolume || ""}
              onChange={(e) =>
                changeHandler("StimaVolume", e.target.value)
              }
              //disabled={checked}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotaleMerce;
