import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  Stack,
} from "@mui/material";
import { Download, FileDownloadOff } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { chipIconState } from "../utilities";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import ActionButton from "./ActionButton";
import { useRef } from "react";

const PODPresenteRender = ({ params, navigate, idElement }) => {
  let path = localStorage.getItem("path");
  let splitPrm = path.split("/");
  if (params.PODPresente === "S") {
    return (
      <Stack spacing={2}>
        <IconButton
          color={"primary"}
          aria-label="upload picture"
          component="span"
          target="_blank"
          onClick={() =>
            window.open(
              `${splitPrm[0]}/pod/${params[idElement]}`,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <Download />
        </IconButton>
      </Stack>
    );
  } else {
    return (
      <Stack spacing={2}>
        <IconButton
          color={"error"}
          aria-label="upload picture"
          component="span"
          disabled
        >
          <FileDownloadOff />
        </IconButton>
      </Stack>
    );
  }
};

const StatoRender = ({ params }) => {
  return chipIconState(params.StatoWeb, params.StatoWebDescrizione);
};

export default function CustomPaginationActionsTable({
  columns,
  rows,
  limit,
  navigate,
  getMethodForList,
  recordCount,
  idElement,
  routeDetail,
  actionShow,
  viewAction,
  filterList,
  configPrint,
  resettaFiltri,
  pageReset,
  setCheckBoxSelectionPrint,
  reload,
  deleteAction,
  emailAction
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [couterApi, setCouterApi] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [checkboxList, setCheckboxList] = React.useState([]);
  const [checkboxSelect, setCheckboxSelect] = React.useState([]);

  const reftable = useRef();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const selectCheckbox = (data, id) => {
    let isMatch = checkboxList.some(function (res) {
      return res[idElement] === id;
    });
    if (isMatch) {
      setCheckboxList([...checkboxList.filter((res) => res[idElement] !== id)]);
      setCheckBoxSelectionPrint([
        ...checkboxList.filter((res) => res[idElement] !== id),
      ]);
    } else {
      setCheckboxList([...checkboxList, data]);
      setCheckBoxSelectionPrint([...checkboxList, data]);
    }
  };

  const onSelectAllClick = () => {
    setCheckboxSelect([
      ...checkboxSelect,
      { selectGroup: page * rowsPerPage + rowsPerPage },
    ]);
    let isMatch = checkboxSelect.some(function (res) {
      return res.selectGroup === page * rowsPerPage + rowsPerPage;
    });
    let selectMulti = [...checkboxList];
    let listFiltrata = [...checkboxList];
    if (checkboxList.length % rowsPerPage !== 0) {
      selectMulti = [];
      listFiltrata = [];
    }
    if (isMatch) {
      rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          listFiltrata.map((res, indx) => {
            if (row[idElement] === res[idElement]) {
              listFiltrata.splice(indx, 1);
            }
          });
        });
      setCheckboxList(listFiltrata);
      setCheckboxSelect(
        checkboxSelect.filter(
          (res) => res.selectGroup !== page * rowsPerPage + rowsPerPage
        )
      );
      setCheckBoxSelectionPrint(listFiltrata);
    } else {
      rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((res) => {
          selectMulti = [...selectMulti, res];
        });
      setCheckboxList(selectMulti);
      setCheckBoxSelectionPrint(selectMulti);
    }
  };

  useEffect(() => {
    if (limit / rowsPerPage === couterApi + 1) {
      getMethodForList(
        "",
        limit,
        rows.length,
        navigate,
        rows,
        recordCount,
        filterList,
      );
      setCouterApi(0);
    } else if (page > 0) {
      setCouterApi(couterApi + 1);
    }
  }, [page, rowsPerPage, limit, rows]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if(reload){
      setCheckboxList([])
      setCheckboxSelect([])
    }
  }, [reload]);

  useEffect(() => {
    if (resettaFiltri) {
      setCouterApi(0);
      setPage(0);
    }
  }, [resettaFiltri]);

  useEffect(() => {
    if (pageReset) {
      setCouterApi(0);
      setPage(0);
    }
  }, [pageReset]);

  return (
    <Box mb={10}>
      <Paper
        sx={{
          width: "100%",
          //overflow: "hidden",
          //border: 1,
          borderColor: (theme) => theme.palette.secondary.main,
        }}
        elevation={1}
      >
        <TableContainer ref={reftable}>
          {loading ? <LinearProgress color="primary" /> : <></>}
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ lineHeight: "0em" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  let uuid = uuidv4();
                  return (
                    <TableCell
                      padding="none"
                      key={uuid + column.minWidth}
                      align="center"
                      style={{
                        //textAlign: "center",
                        minWidth:
                          column.field === "checkbox" && actionShow
                            ? 60
                            : column.minWidth,
                      }}
                    >
                      {column.field === "checkbox" && actionShow ? (
                        <Checkbox
                          size="small"
                          color="primary"
                          onChange={() => onSelectAllClick()}
                          checked={checkboxSelect.some(function (res) {
                            return (
                              res.selectGroup ===
                              page * rowsPerPage + rowsPerPage
                            );
                          })}
                        />
                      ) : (
                        column.headerName
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        align="center"
                        tabIndex={-1}
                        key={uuidv4()}
                      >
                        {columns.map((column) => {
                          let value;
                          if (
                            column.field === "PODPresente" ||
                            column.field === "StatoWebDescrizione"
                          ) {
                            value = "";
                          } else if (column.field === "action") {
                            value = (
                              <ActionButton
                                routeDetail={routeDetail}
                                data={row}
                                idElement={idElement}
                                viewAction={viewAction}
                                configPrint={configPrint}
                                deleteAction={(e)=>deleteAction(e)}
                                emailAction={(e)=>emailAction(e)}
                              />
                            );
                          } else if (column.field === "checkbox") {
                            value = actionShow ? (
                              <Checkbox
                                size="small"
                                color="primary"
                                checked={checkboxList.some(function (res) {
                                  return res[idElement] === row[idElement];
                                })}
                                onChange={() =>
                                  selectCheckbox(row, row[idElement])
                                }
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                              />
                            ) : null;
                          } else {
                            let appoggioValue = row[column.field]?.toString()
                            if (appoggioValue?.includes('<br>')) {
                              value= row[column.field]
                              .toString()
                              ?.split("<br>")
                              .map((res, key) => {
                                return  <div key={key}>{res}</div>;
                              })
                            } else {
                              value = row[column.field];
                            }
                          }
                          let uuid = uuidv4();
                          return (
                            <TableCell
                              key={uuid}
                              //style={{ textAlign: "center" }}
                              padding="none"
                              align="center"
                            >
                              {value}
                              {column.field === "PODPresente" ? (
                                <PODPresenteRender
                                  params={row}
                                  navigate={navigate}
                                  idElement={idElement}
                                />
                              ) : (
                                ""
                              )}
                              {column.field === "StatoWebDescrizione" ? (
                                <StatoRender params={row} />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan="1" style={{ textAlign: "center" }}>
                    <CustomNoRowsOverlay />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {recordCount > 0 ? (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button sx={{ fontSize: 15 }}>
              {checkboxList.length > 0 ? checkboxList.length : ""}
            </Button>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={recordCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        ) : (
          <></>
        )}
      </Paper>
    </Box>
  );
}
